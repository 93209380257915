import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import TherapySessionPage from './TherapySessionPage';
import HomePage from './HomePage';
import Show from './Show';
// import LoginInfoPage from './LoginInfoPage';
import { ChatDots, CardList } from 'react-bootstrap-icons';

import Footer from './Footer';
import AITherapyNeedPage from './AITherapyNeedPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import DisclaimerPage from './DisclaimerPage';
import AcceptableUsePolicyPage from './AcceptableUsePolicyPage';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [therapySessionType, setTherapySessionType] = useState('voice');
  const [userState, setUserState] = useState({
    messages: [],
    voiceName: 'nova', // default value for voiceName
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand-logo">mona.cyou</Navbar.Brand>
          {/* <Nav className="ms-auto">
            <NavLink to="/therapy-session" className="nav-link">
              <ChatDots size={24} />
            </NavLink>
            <NavLink to="/thesis" className="nav-link">
              <CardList size={24} />
            </NavLink>
            <NavLink to="/show" className="nav-link">
              <CardList size={24} />
            </NavLink>
          </Nav> */}
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<HomePage userState={userState} setUserState={setUserState} />} />
        <Route
          path="/therapy-session"
          element={
            <TherapySessionPage
              therapySessionType={therapySessionType}
              setTherapySessionType={setTherapySessionType}
              userState={userState}
              setUserState={setUserState}
            />
          }
        />
        <Route path="/thesis" element={<AITherapyNeedPage />} />
        <Route path="/show" element={<Show />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/acceptable-use-policy" element={<AcceptableUsePolicyPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
