import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import cardData from './cardData.json';
import './HomePage.css';

function HomePage({ userState, setUserState }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const packagesRef = useRef(null);

  const startSession = (packageAssistantId, voiceName) => {
    setUserState({
      ...userState,
      agentId: packageAssistantId,
      voiceName: voiceName,
    });
    navigate(`/therapy-session`);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
    setShowModal(false);
  };

  const scrollToPackages = () => {
    packagesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Container className="mt-4 mb-4">
        {/* <Card className="mb-4 custom-bg-color">
          <Card.Body>
            <div className="marketing-section">
              <h2 className="marketing-title">AI Therapist, Coach and Counselor Designed for College Students</h2>
              <p className="marketing-text">
                Our innovative platform provides you with tailored support, guidance, and resources to help you navigate the challenges of academic life and maintain your mental well-being.
              </p>
              <div className="row">
                <h4 className="feature-title">Select a package below and start now!</h4>
              </div>
            </div>
          </Card.Body>
        </Card> */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" ref={packagesRef}>
          {cardData.map((card, index) => (
            <div key={index} className="col">
              <Card className="shadow-sm">
                <Card.Img variant="top" src={card.image} />
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.problem}</Card.Text>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <Button variant="dark" onClick={() => startSession(card.packageAssistantId, card.voiceName)}>
                      Start Session
                    </Button>
                    <Button variant="outline-secondary" size="sm" onClick={() => handleCardClick(card)}>
                      More Info
                    </Button> */}
                    <Button variant="dark" onClick={() => navigate('/show')}>
                      Check it Out!
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        {selectedCard && (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">{selectedCard.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-text"><strong>Problem:</strong> {selectedCard.problem}</p>
              <p className="modal-text"><strong>Issues:</strong> {selectedCard.issues}</p>
              <p className="modal-text"><strong>Goal:</strong> {selectedCard.goal}</p>
              <p className="modal-text"><strong>Approach:</strong> {selectedCard.approach}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" onClick={() => startSession(selectedCard.packageAssistantId, selectedCard.voiceName)}>
                Start 30-min Session
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default HomePage;
