import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight, BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import transcriptData from './mona_improv_061324.json';
import audioFeedback from './audio_feedback';
import './Show.css';

const Show = () => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const totalFrames = 3248; // Total number of frames
  const youtubeVideoId = 'vcaML3KANLI'; // YouTube video ID
  const audioRef = useRef(null);

  const framesPerSecond = 1;

  // Generate the URL for the current frame image
  const getFrameImageUrl = (frameNumber) => {
    const frameString = frameNumber.toString().padStart(4, '0'); // Ensure frame number is 4 digits
    return `${process.env.PUBLIC_URL}/extracted_frames/frame_${frameString}.webp`;
  };

  const handleSliderChange = (e) => {
    setCurrentFrame(Number(e.target.value));
  };

  const handleNextFrame = () => {
    if (currentFrame + framesPerSecond < totalFrames) {
      setCurrentFrame(currentFrame + framesPerSecond);
    }
  };

  const handlePreviousFrame = () => {
    if (currentFrame - framesPerSecond >= 0) {
      setCurrentFrame(currentFrame - framesPerSecond);
    }
  };

  const handleCardClick = (minute) => {
    const newFrame = minute * 60 * framesPerSecond;
    setCurrentFrame(newFrame);
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const minutes = date.getUTCMinutes();
    const secs = date.getUTCSeconds();
    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  };

  const playAudio = (start) => {
    if (audioRef.current) {
      audioRef.current.currentTime = start;
      audioRef.current.play();
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const getTranscriptForMinute = (minute) => {
    const startTime = minute * 60;
    const endTime = startTime + 60;
    return transcriptData.filter((item) => item.start >= startTime && item.end <= endTime);
  };

  const getFeedbackForMinute = (minute) => {
    return audioFeedback.find((item) => item.start <= minute && item.end > minute);
  };

  const handleDiscussWithAI = () => {
    alert('Coming Soon!');
  };

  return (
    <Container className="mt-4 mb-4">
      <Row className="my-3">
        <Col md={4}>
          <Card className="mb-3 no-padding" style={{ backgroundColor: '#F8F8F6', marginBottom: '0px' }}>
            <Card.Header className="p-2 d-flex justify-content-between align-items-center title-font" 
                         style={{ padding: '10px 15px', fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
              <span>Image Frame (Time: {formatTime(currentFrame)})</span>
              <div>
                <Button variant="outline-dark" size="sm" onClick={handlePreviousFrame} className="me-2">
                  <BsChevronLeft size={20} />
                </Button>
                <Button variant="outline-dark" size="sm" onClick={handleNextFrame}>
                  <BsChevronRight size={20} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="no-padding" style={{ padding: 0, fontFamily: 'inherit' }}>
              <img src={getFrameImageUrl(currentFrame)} alt={`Frame ${currentFrame}`} className="img-fluid" />
            </Card.Body>
            <Card.Footer>
              <Form.Group controlId="frameSlider">
                <Form.Label>Navigate Frames</Form.Label>
                <Form.Control
                  type="range"
                  min="0"
                  max={totalFrames - 1}
                  value={currentFrame}
                  onChange={handleSliderChange}
                />
                <Form.Text className="text-muted">
                  Frame {currentFrame + 1} of {totalFrames}
                </Form.Text>
              </Form.Group>
            </Card.Footer>
          </Card>
          <Card className="mt-3 no-padding" style={{ backgroundColor: '#F8F8F6' }}>
            <Card.Header className="p-2 title-font" style={{ padding: '10px 15px', fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>Video</Card.Header>
            <Card.Body className="no-padding" style={{ padding: 0, fontFamily: 'inherit' }}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Improv Show"
                  className="embed-responsive-item"
                  src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                  allowFullScreen
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          {[...Array(Math.ceil(totalFrames / framesPerSecond / 60)).keys()].map((minute) => (
            <Card 
              key={minute} 
              className="mb-3 clickable-card" 
              style={{ backgroundColor: '#F8F8F6', marginBottom: '0px', cursor: 'pointer' }}
              onClick={() => handleCardClick(minute)}
            >
              <Card.Header 
                style={{ padding: '10px 15px', fontSize: '1rem', fontWeight: 'bold', backgroundColor: '#e0e0e0', cursor: 'pointer' }}
              >
                <span>{minute} - {minute + 1} min | Transcript and Critique</span>
                <Button variant="outline-dark" size="sm" onClick={() => playAudio(minute * 60)} className="ms-2">
                  <BsFillPlayFill />
                </Button>
                <Button variant="outline-dark" size="sm" onClick={stopAudio} className="ms-2">
                  <BsFillPauseFill />
                </Button>
                <Button variant="outline-dark" size="sm" onClick={handleDiscussWithAI} className="ms-2">
                  Discuss with AI
                </Button>
              </Card.Header>
              <Card.Body style={{ padding: 0, fontFamily: 'inherit' }}>
                <div className="transcript-section">
                  {getTranscriptForMinute(minute).map((item, index) => (
                    <p key={index} className="transcript-text">{item.text}</p>
                  ))}
                </div>
                <div className="feedback-section">
                  {getFeedbackForMinute(minute) ? (
                    getFeedbackForMinute(minute).feedback.map((feedback, index) => (
                      <div key={index} className="feedback-item">
                        <p className="feedback-title">{feedback.title}</p>
                        <p>{feedback.content}</p>
                        <p className="feedback-suggestions">{feedback.suggestions}</p>
                      </div>
                    ))
                  ) : (
                    <p className="no-feedback">No feedback available for this minute.</p>
                  )}
                </div>
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
      <audio ref={audioRef} src={`${process.env.PUBLIC_URL}/mona_improv_061324.mp3`} />
    </Container>
  );
};

export default Show;
