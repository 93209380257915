const audioFeedback = [
    {
        start: 0,
        end: 1,
        feedback: [
            {
                title: "Introduction",
                content: "The opening was energetic, but a bit scattered. It’s great to start with high energy, but it’s also important to stay focused. Try to streamline the introduction to avoid sounding repetitive or disorganized.",
                suggestions: "Consider scripting your initial greeting or having a clear outline so you don’t lose the audience early."
            }
        ]
    },
    {
        start: 1,
        end: 2,
        feedback: [
            {
                title: "Information Overload",
                content: "You provided useful information about shows and classes, but it felt jumbled.",
                suggestions: "Segment information better, maybe use bullet points or ask for a response from the audience to keep them engaged (e.g., 'Who’s interested in stand-up comedy?')."
            }
        ]
    },
    {
        start: 2,
        end: 3,
        feedback: [
            {
                title: "Transition to Show",
                content: "Good enthusiasm when introducing Miranda Rosner.",
                suggestions: "Ensure that transitions are smooth and reinforce the key points (e.g., introduce the teacher, explain the format briefly, and then transition to the performance)."
            }
        ]
    },
    {
        start: 3,
        end: 4,
        feedback: [
            {
                title: "Scene Setting",
                content: "The actors jumped into a scene set in Derbyshire.",
                suggestions: "Great location specificity! However, double-check accents and cultural references to keep them accurate. Enhance clarity and context to avoid confusion."
            }
        ]
    },
    {
        start: 4,
        end: 5,
        feedback: [
            {
                title: "Dialogue",
                content: "The dialogue about diapers was humorous but felt out of place.",
                suggestions: "Keep track of the scene’s focus – if it’s a sentimental reunion, maintain that tone. If the goal is absurd humor, then commit to it fully."
            }
        ]
    },
    {
        start: 5,
        end: 6,
        feedback: [
            {
                title: "Character Consistency",
                content: "Characters seemed a bit inconsistent.",
                suggestions: "Keep character motivations and backgrounds consistent to help the audience follow the narrative. Revisit character details with the group if necessary."
            }
        ]
    },
    {
        start: 6,
        end: 7,
        feedback: [
            {
                title: "Confusion in Dialogue",
                content: "The scene became somewhat confusing and lost direction.",
                suggestions: "Focus on clear objectives for each scene. Ensure each actor knows their character's desires and how they align with the plot."
            }
        ]
    },
    {
        start: 7,
        end: 8,
        feedback: [
            {
                title: "Random Dialogue Elements",
                content: "Introductory dialogue about documents and job confusion was hard to follow.",
                suggestions: "Establish the primary conflict or punchline earlier in the scene. Get to the core of the improv quickly to maintain interest."
            }
        ]
    },
    {
        start: 8,
        end: 9,
        feedback: [
            {
                title: "Engagement Drop",
                content: "Seems like the interest level dropped due to unclear story progression.",
                suggestions: "Ensure that every exchange pushes the narrative forward. Avoid dead-end dialogue."
            }
        ]
    },
    {
        start: 9,
        end: 10,
        feedback: [
            {
                title: "Lost Identity within Scene",
                content: "The “John” character and identity confusion was stretched.",
                suggestions: "Clarify character identities early and stick with it. Misdirection is fine but resolve confusion promptly for satisfying punchlines."
            }
        ]
    },
    {
        start: 10,
        end: 11,
        feedback: [
            {
                title: "Repetitiveness",
                content: "Dialogue about rules and teaching got repetitive without adding humor or plot.",
                suggestions: "Avoid endlessly cycling dialogue without progress. Introduce new elements or resolve conflicts faster."
            }
        ]
    },
    {
        start: 11,
        end: 12,
        feedback: [
            {
                title: "Scene Transition",
                content: "Introduce new plot about a children’s book but lacked clarity.",
                suggestions: "Clear context and motivation needed. Who are they talking to? What’s the goal of the conversation?"
            }
        ]
    },
    {
        start: 12,
        end: 13,
        feedback: [
            {
                title: "Ten-Year Reunion",
                content: "Great premise but execution was messy.",
                suggestions: "Keep it tight. Stick to one or two main punchlines or conflicts per scene."
            }
        ]
    },
    {
        start: 13,
        end: 14,
        feedback: [
            {
                title: "Overwhelming Characters",
                content: "Too many characters and details about their lives made it hard to follow.",
                suggestions: "Limit the number of characters or focus on a central interaction within each scene."
            }
        ]
    },
    {
        start: 14,
        end: 15,
        feedback: [
            {
                title: "Lost Focus",
                content: "Continued detailing unimportant information.",
                suggestions: "Prioritize punchlines or interesting character details. Avoid overexplaining."
            }
        ]
    },
    {
        start: 15,
        end: 16,
        feedback: [
            {
                title: "Dialogues Recycling",
                content: "Similar conversations as previous minutes; seemed like filler content.",
                suggestions: "Introduce fresh conflicts or humor. Avoid redundancy."
            }
        ]
    },
    {
        start: 16,
        end: 17,
        feedback: [
            {
                title: "Phone Confusion",
                content: "Dialogue with John and phone was again unclear and lengthy.",
                suggestions: "Shorten confusing elements. Identify clear punchlines swiftly."
            }
        ]
    },
    {
        start: 17,
        end: 18,
        feedback: [
            {
                title: "Redundant Exit Dialogue",
                content: "Series of goodbyes stretched too long.",
                suggestions: "Make goodbyes quick or incorporate a final humorous act for closure."
            }
        ]
    },
    {
        start: 18,
        end: 19,
        feedback: [
            {
                title: "Introduction of New Game",
                content: "Sex with Me game introduced well; clear explanation.",
                suggestions: "Outline game rules quickly and jump into execution."
            }
        ]
    },
    {
        start: 19,
        end: 20,
        feedback: [
            {
                title: "Clear Expectations",
                content: "Players responded well to the game’s premise with creative lines.",
                suggestions: "Encourage varied and unexpected responses to keep audience engaged."
            }
        ]
    },
    {
        start: 20,
        end: 21,
        feedback: [
            {
                title: "Sex with Me Game Continuation",
                content: "Actors kept the energy and humor.",
                suggestions: "Rotate speakers to keep it fresh and maintain humor."
            }
        ]
    },
    {
        start: 21,
        end: 22,
        feedback: [
            {
                title: "Continuous Laughter",
                content: "Audience engaged with repetitive but well-executed humor.",
                suggestions: "Wrap up games before they become stale; move to new concepts faster."
            }
        ]
    },
    {
        start: 22,
        end: 23,
        feedback: [
            {
                title: "Series of Ha!’s",
                content: "The sequence of laughter lines (Ha!) indicates perhaps a fun, nonsensical performance.",
                suggestions: "Make sure to include variety and avoid overkill unless it serves a strong comedic purpose (e.g., comedic absurdity)."
            }
        ]
    },
    {
        start: 23,
        end: 24,
        feedback: [
            {
                title: "Repetitive Laughter",
                content: "Continued 'Ha!' sequences might feel repetitive for the audience.",
                suggestions: "Walk the line between too many repetitive jokes and keeping them funny – freshen up routines frequently."
            }
        ]
    },
    {
        start: 24,
        end: 25,
        feedback: [
            {
                title: "Character Interaction",
                content: "Change in style and interaction but lacks connection to prior scenes.",
                suggestions: "Integrate scenes better. Ensure transitions between games/scenes aren’t jarring."
            }
        ]
    },
    {
        start: 25,
        end: 26,
        feedback: [
            {
                title: "Game Connection",
                content: "New interaction following the game; maintain the energy.",
                suggestions: "Keep audience involved actively, especially after high-energy games."
            }
        ]
    },
    {
        start: 26,
        end: 27,
        feedback: [
            {
                title: "Fine Lines Game",
                content: "Utilizing keywords well.",
                suggestions: "Encourage diversity in punchlines, especially for common words to maintain unpredictability."
            }
        ]
    },
    {
        start: 27,
        end: 28,
        feedback: [
            {
                title: "Keyword Game",
                content: "Keeps engagement.",
                suggestions: "Reflect on fewer 'easy' punchlines; aim for clever, unexpected humor."
            }
        ]
    },
    {
        start: 28,
        end: 29,
        feedback: [
            {
                title: "Wrap-Up of Game",
                content: "Good closure to the game, shifting to group interaction.",
                suggestions: "Ensure transition to next scene/game is seamless and engaging."
            }
        ]
    },
    {
        start: 29,
        end: 30,
        feedback: [
            {
                title: "Group Engagement",
                content: "Good enthusiasm in the group cheering.",
                suggestions: "Keep audience participation high but focused to avoid chaos."
            }
        ]
    },
    {
        start: 30,
        end: 31,
        feedback: [
            {
                title: "Maintaining Energy",
                content: "Managed to keep energy levels high.",
                suggestions: "Focus on cohesion and clarity in next acts."
            }
        ]
    },
    {
        start: 31,
        end: 32,
        feedback: [
            {
                title: "Back to Scene Work",
                content: "Scene transitions observed again.",
                suggestions: "Maintain clear storylines and avoid overly complex scenarios which can confuse the audience."
            }
        ]
    },
    {
        start: 32,
        end: 33,
        feedback: [
            {
                title: "Scene Interaction",
                content: "Seemed more coherent.",
                suggestions: "Stick to simpler narratives for more effective execution."
            }
        ]
    },
    {
        start: 33,
        end: 34,
        feedback: [
            {
                title: "Consistent Interaction",
                content: "Maintained character consistency.",
                suggestions: "Focus on clear conflicts or objectives in scenes to avoid flat lines."
            }
        ]
    },
    {
        start: 34,
        end: 35,
        feedback: [
            {
                title: "Sudden Shift",
                content: "Transitioning back and forth between ideas.",
                suggestions: "Integrate narrative coherence within shifts to keep audience on track."
            }
        ]
    },
    {
        start: 35,
        end: 36,
        feedback: [
            {
                title: "Dialoguing Events",
                content: "New scenario but needs depth.",
                suggestions: "Dive deeper into character development or humor for richer interactions."
            }
        ]
    },
    {
        start: 36,
        end: 37,
        feedback: [
            {
                title: "Variety in Characters",
                content: "Displayed variety in characters but with gaps in narrative clarity.",
                suggestions: "Stronger linkage between scenes and consistent character arcs."
            }
        ]
    },
    {
        start: 37,
        end: 38,
        feedback: [
            {
                title: "Clarity in Storytelling",
                content: "Clarity dropped here with scattered interactions.",
                suggestions: "Simplify objectives and keep storylines consistent."
            }
        ]
    },
    {
        start: 38,
        end: 39,
        feedback: [
            {
                title: "Conclusion Nearing",
                content: "Began wrapping up scenes.",
                suggestions: "Engage with impactful closing humor or surprise."
            }
        ]
    },
    {
        start: 39,
        end: 40,
        feedback: [
            {
                title: "Maintaining Engagement",
                content: "Continued interaction with effort but needs a clearer wrap-up.",
                suggestions: "Tighten the ending, ensuring it links earlier scenes or provides satisfying close."
            }
        ]
    },
    {
        start: 40,
        end: 41,
        feedback: [
            {
                title: "Extended Laughter Sequence",
                content: "The laughter sequences (Ha!'s) seem to be a running gag.",
                suggestions: "While high energy is good, ensure there's a variety in delivery and context to keep the audience genuinely engaged. Repetition can be humorous if the pacing escalates or alters creatively."
            }
        ]
    },
    {
        start: 41,
        end: 42,
        feedback: [
            {
                title: "Continued Laughter",
                content: "The 'Ha!' sequence persists without major variation.",
                suggestions: "Balance repetition with fresh elements. Introducing a twist or unexpected change can reinvigorate the scene – perhaps add a new context or character reacting differently."
            }
        ]
    },
    {
        start: 42,
        end: 43,
        feedback: [
            {
                title: "Laughter Continues",
                content: "Persistent laughter lines could risk becoming monotonous.",
                suggestions: "Gauge audience reactions; if energy wanes, switch up the dynamic. Introduce an interruption or a contrasting element for relief."
            }
        ]
    },
    {
        start: 43,
        end: 44,
        feedback: [
            {
                title: "Saturation Point",
                content: "Repetition is reaching a saturation point.",
                suggestions: "Consider wrapping up extended gags sooner. Transition to a different activity or narrative that renews audience interest."
            }
        ]
    },
    {
        start: 44,
        end: 45,
        feedback: [
            {
                title: "Transition Needed",
                content: "The continuous 'Ha!' needs a break or a redirect.",
                suggestions: "Use a clear signal (e.g., bell, loud noise) to shift scenes. Bring in fresh content or start a completely new improv game to reset energy levels."
            }
        ]
    },
    {
        start: 45,
        end: 46,
        feedback: [
            {
                title: "Monotony Risk",
                content: "Monotony creeping in, risking audience disengagement.",
                suggestions: "Time for a new segment. Reinvent the scene with a surprise character entrance or a sudden plot twist."
            }
        ]
    },
    {
        start: 46,
        end: 47,
        feedback: [
            {
                title: "Scene Recovery",
                content: "Introduce diverse elements to revive momentum.",
                suggestions: "Quick, engaging games or interactive segments with the audience can rekindle interest. Consider short-form games that keep a rapid pace."
            }
        ]
    },
    {
        start: 47,
        end: 48,
        feedback: [
            {
                title: "Audience Involvement",
                content: "Gradually reintroduce interactive audience elements.",
                suggestions: "Ask for suggestions, quick participation games, or scene changes. Utilize something unexpected to surprise and delight."
            }
        ]
    },
    {
        start: 48,
        end: 49,
        feedback: [
            {
                title: "Engagement Reaction",
                content: "Bring the audience back with a direct and engaging activity.",
                suggestions: "Improv games with visual humor or physical comedy can be very effective at this stage. Ensure high energy but controlled focus to not lose engagement again."
            }
        ]
    },
    {
        start: 49,
        end: 50,
        feedback: [
            {
                title: "Introduction of Mr. Green",
                content: "New character introduction (Mr. Green) hints at new direction.",
                suggestions: "Develop Mr. Green’s character quickly and clearly. Have a strong, humorous through-line for his presence to be effective."
            }
        ]
    },
    {
        start: 50,
        end: 51,
        feedback: [
            {
                title: "Character Play",
                content: "Use Mr. Green and surrounding characters to establish a quick, funny scenario.",
                suggestions: "Focus on Mr. Green’s quirks or a unique trait that provokes interaction. Establish a fast-paced dialogue that keeps the humor flowing."
            }
        ]
    },
    {
        start: 51,
        end: 52,
        feedback: [
            {
                title: "Maintaining Humor",
                content: "Mr. Green’s hat and body remark were mildly humorous.",
                suggestions: "Keep dialogues punchy and to the point, avoiding unnecessary details that may slow the momentum. Use physical comedy to enhance the scene."
            }
        ]
    },
    {
        start: 52,
        end: 53,
        feedback: [
            {
                title: "Clarence and Secrets",
                content: "Interaction about market and secrets opens potential for humor.",
                suggestions: "Play up the suspicion and reveal humorous secrets. Use escalating reveals to build the comedy climax."
            }
        ]
    },
    {
        start: 53,
        end: 54,
        feedback: [
            {
                title: "Interaction Feature",
                content: "Clarence’s hat and secret woman add comedic layers.",
                suggestions: "Solidify the punchline of Clarence’s story – make the reveal just exaggerated enough to be funny without overblowing it."
            }
        ]
    },
    {
        start: 54,
        end: 55,
        feedback: [
            {
                title: "Limited Edition Bit",
                content: "Repetition of 'limited edition, bitch' requires careful handling.",
                suggestions: "Offer variety in the way it's delivered each time or tie it into a surprising final punchline."
            }
        ]
    },
    {
        start: 55,
        end: 56,
        feedback: [
            {
                title: "Alien Ship Context",
                content: "Engage with the location/theme (alien ship) to branch out humor.",
                suggestions: "Use absurdity of an alien context to introduce imaginative scenarios and physical comedy."
            }
        ]
    },
    {
        start: 56,
        end: 57,
        feedback: [
            {
                title: "Repetitive Concerns",
                content: "Concerns about sleeping and rounds on alien ship need punch.",
                suggestions: "Introduce an unexpected twist or alien character to reinvigorate the scene. Use the alien setting to create bizarre, hilarious situations."
            }
        ]
    },
    {
        start: 57,
        end: 58,
        feedback: [
            {
                title: "New Elements",
                content: "Introduce new elements or characters to freshen the narrative.",
                suggestions: "Bring in surprises, loud reactions, or sudden changes that catch the audience off guard and re-engage their interest."
            }
        ]
    },
    {
        start: 58,
        end: 59,
        feedback: [
            {
                title: "Conclusion Approach",
                content: "Nearing the end, ensure a strong, coherent finish.",
                suggestions: "Summarize key humorous events, wrap up ongoing gags, and ensure the audience leaves with a strong, memorable laugh."
            }
        ]
    },
    {
        start: 59,
        end: 60,
        feedback: [
            {
                title: "Consistency in Characters",
                content: "Maintain consistent character traits and motivations throughout scenes for coherence.",
                suggestions: "Introduce a variety of humor types – physical, verbal, situational – to keep audiences engaged. Utilize audience interaction thoughtfully and seamlessly to prevent energy dips. Keep the pace brisk. Slow moments should have purpose or be played with a distinct comedic effect. Even within short scenes, try to establish and resolve mini-story arcs to provide satisfying pay-offs for the audience."
            }
        ]
    }
];

export default audioFeedback;
